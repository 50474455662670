import { combineReducers } from 'redux'
import currentUser from './reducers/current-user'
import patients from './reducers/patients'
import renderedServices from './reducers/rendered-services'

const rootReducer = combineReducers({
  currentUser,
  patients,
  renderedServices
})

export default rootReducer
