import { ACCESS_TOKEN_KEY, CURRENT_USER_ID } from '../../constants'
import { storeKey, removeAuthKeys } from '../../functions'

export const AUTHENTICATE = 'AUTHENTICATE'

export default function reducer (state = null, { type, payload }) {
  switch (type) {
    case AUTHENTICATE:
      return {
        ...state,
        user: { ...payload.user }
      }
    default:
      return state
  }
}

export function authenticate (user) {
  return {
    type: AUTHENTICATE,
    payload: { user }
  }
}

export function logoutUser () {
  return function (dispatch, getstate, { baseApi }) {
    return baseApi.logoutUser().then(function () {
      removeAuthKeys()
      dispatch(authenticate(null))
    })
  }
}

export function loginUser (values) {
  return function (dispatch, getstate, { baseApi }) {
    removeAuthKeys()

    return baseApi.loginUser(values).then(function (token) {
      storeKey(ACCESS_TOKEN_KEY, token)
      return token
    })
  }
}

export function verifyCurrentUser (token = null) {
  return function (dispatch, getstate, { baseApi, healthcareApi }) {
    return baseApi.verifyCurrentUser(token).then(user => {
      storeKey(CURRENT_USER_ID, user.id)

      return healthcareApi.loadHealthcareProviderProfile().then(userProfile => {
        authenticate(userProfile)
        return userProfile
      })
    })
  }
}

export function resetPassword (email) {
  return function (dispatch, getstate, { baseApi }) {
    return baseApi.resetPassword(email)
  }
}

export function updatePassword (params) {
  return function (dispatch, getState, { baseApi }) {
    return baseApi.updatePassword(params).then(function (response) {
      return response
    })
  }
}
