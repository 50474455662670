import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose
} from 'redux'
import { batchedSubscribe } from 'redux-batched-subscribe'
import { debounce } from 'lodash'
import { withExtraArgument } from 'redux-thunk'
import reducer from './reducer'
import baseApi from '../api'
import healthcareApi from '../api/healthcare'
import * as schema from '../api/schema'

let composeEnhancers = compose

if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    actionsDenylist: []
  })
}

const enhancer = composeEnhancers(
  applyMiddleware(withExtraArgument({ baseApi, healthcareApi, schema })),
  batchedSubscribe(debounce(notify => notify()))
)

const defaultState = {}

export default createStore(reducer, defaultState, enhancer)
